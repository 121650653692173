.flex-container {
    display: flex;
}

.flex-row {
    display: flex;
}

.section-header-container {
    display: flex;
    margin: 0 1rem;
}
.widget-toolbar-container {
    display: flex;
    margin: 0 1rem;
}

.tool-bar-item-container {
    display: flex;
}

.widget-toolbar-kpi {
    width: 70%;
}

.tool-bar-item {
    margin-right: 0.5rem;
    flex-grow: 1;
}

.tool-bar-item-1 {
    flex-grow: 2;
}

.tool-bar-checkbox {
    max-width: max-content;
}

.tool-bar-item-2 {
    margin: 0 1rem;
    width: 100%;
}

.section-widgets-container {
    display: flex;
    margin: 0.5rem;
}

.section-widgets-container-data-grid {
    flex-direction: column;
}

.widget-container {
    display: flex;
}

.widget-container-data-grid {
    flex-direction: column;
    width: 100%;
    padding: 0.5rem 0 0 0.5rem;
    border-radius: 1rem;
    border: 1px solid #e6e6e6;
    border-radius: 0.5rem;
    background: #fff;
}

.widget-toolbar-data-grid {
    width: 80%;
    margin-left: -1rem;
    margin-bottom: 1rem;
}


.widget-title {
    flex-grow: 1;
}

.grid-title {
    margin-bottom: 10px;
    margin-right: 10px;
    margin-top: 0;
    flex-grow: 0;
}

h2.grid-title {
    color: #333333;
    font-size: 1.5rem;
    font-family: "Open Sans";
}

.bottom-line {
    /* width: 15rem; */
    height: 8px;
    border-top: solid 1px #ccc;
    margin-bottom: 0.5rem;
    margin-top: 4px;
    position: relative;
}

.decorative-bar.medium {
    height: 3px;
    margin-top: -2px;
}

.decorative-bar {
    position: absolute;
    width: 50px;
}

.bg-blue-p {
    background-color: #005eaa !important;
    color: #fff !important;
}

p {
    margin-bottom: 0;
}