.container {
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 0.5rem;
    position: relative;
    margin: 0;
    box-shadow: 0px 1px 4px 0px grey;
  }
  @media (min-width: 1200px){
    .container{
      max-width: 1940px;
  }
  }


  .chart-header-container {
    grid-area: header;
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 0 0 0.5rem;
    color: rgb(71, 95, 123);
    padding: 0.5rem;
    margin-bottom: 0.1rem;
}
.chart-header{
  color: rgb(71, 95, 123);
  margin: 0;
  padding: 0.5rem 1rem 0;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
  
  .absolute-position {
    position: absolute;
  }
  
  .header {
    padding: 1rem;
  }
  
  .expand-icon{
    transform: scale(1);
    position: absolute;
    right: 0;
    bottom: 0;
   }
   
   .expand-icon:hover{
     transform: scale(1.3);
     cursor: pointer;
   }

   .expand-icon-custom-card {
     transform: scale(0.8);
   }

   .expand-icon-custom-card:hover{
    transform: scale(1);
    cursor: pointer;
  }

   .widget-info-icon {
     transform: scale(1);
     vertical-align: baseline;
   }

  .widget-info-icon:hover{
    transform: scale(1.2);
    cursor: pointer;
  }

  .widget-info-body-container {
    min-height: 12rem;
    justify-content: space-between;
  }

  .widget-info-footer {
    align-self: end;
  }

   .view-more-span{
    margin-left: 75%;
    margin-bottom: 20px ;
    cursor: pointer;
    color: #1ab394;
   }

  .widget-modal-body{ 
    width: 50rem;
    height: 30rem;
    display: flex;
    flex-direction: column;
   }

  .textarea-widget-info {
    outline: none;
    resize: none;
    background: inherit;
    color: inherit;
    width: 100%;
    min-height: 7rem;
    overflow: visible;
    padding: 1rem;
    border: none;
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
  }

  .css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
    background-image: linear-gradient(
        rgba(255, 255, 255, 0.3), 
        rgba(255, 255, 255, 0.8));
  }
    

   .chart-btn{
    float: right;
    padding: 6px;
    margin-top: -36px;
    border-radius: 5px;
    box-shadow: 0px 1px 2px 0px grey;
   }
   table th{
    width: 10rem;
   }
.table{
  margin-top: 2rem;
  overflow-y: auto;

}

.css-loading-indicator {
  color: hsl(0, 0%, 80%);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 8px;
  -webkit-transition: color 150ms;
  transition: color 150ms;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  font-size: 40px;
  line-height: 1;
  margin-right: 4px;
  text-align: center;
  vertical-align: middle;
  box-sizing: border-box;
}

@keyframes css-1 {
  from { background-color: white; }
  to { background-color: #0f52ba; }
}

.css-1-Loading-dot {
  animation: css-1 1s ease-in-out 320ms infinite;
  background-color: currentColor;
  border-radius: 1em;
  display: inline-block;
  margin-left: 1em;
  height: 1em;
  vertical-align: top;
  width: 1em;
}

@keyframes css-2 {
  from { background-color: white; }
  to { background-color: #0f52ba; }
}

.css-2-Loading-dot {
  animation: ccs-2 1s ease-in-out 160ms infinite;
  background-color: currentColor;
  border-radius: 1em;
  display: inline-block;
  margin-left: 1em;
  height: 1em;
  vertical-align: top;
  width: 1em;
}

@keyframes css-3 {
  from { background-color: white; }
  to { background-color: #0f52ba; }
}

.css-3-Loading-dot {
  animation: css-3 1s ease-in-out 0ms infinite;
  background-color: currentColor;
  border-radius: 1em;
  display: inline-block;
  height: 1em;
  vertical-align: top;
  width: 1em;
}

.carousel-button {
  max-width: 0.5rem;
  margin: 0 -0.5rem;
  cursor: pointer;
  opacity: 0.5;
  z-index: 999;
}

.carousel-button:hover {
  max-width: 0.8rem;
  opacity: 1;
}
