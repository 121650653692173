@import url(https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/css/bootstrap.min.css);
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap");



body{
    font-family: "Rubik", sans-serif;
    overflow: auto;
    padding-right: 0;
    padding-left: 0;
    box-sizing:border-box;
    margin: 0;
    width: 100vw;
    max-width:100%;
    height: 100vh;
}

.container-context{
    display: flex;
    flex-direction: row;
    overflow-x: hidden;
}
.container-body{
    display: flex;
    background-color: rgb(233, 238, 243); 
}

.right-container{
    width: 100%;
    margin: 7px;  
}
.chart-style{
    background-color: white;
    border-radius: 6px;
}
.content{
    display: flex;
    flex-direction: row;
}
/* ---Sidebar css */
.text-primary{
  color: #2955A2!important;
}
.btn-primary {
    border-color: #2955A2 !important;
    background-color: #2955A2 !important;
    color: #fff;
}
/* .shadow-md {
    box-shadow: 0px 5px 10px rgba(90, 116, 148, 0.3);
}
.navbar{
  border-radius: 5px;
  margin: 1rem;
} */

