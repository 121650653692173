
.rs-form {
  padding: 1rem;
  min-width: 15rem;
  max-width: 25rem;
  margin: auto;
}

.rs-form-2 {
  padding: 1rem;
  min-width: 15rem;
  max-width: 40rem;
  margin: auto;
}

.rs-form-group {
  flex: 1;
}

.rs-label {
  text-align: left;
  color: #666;
  font-size: 12px;
  margin-bottom: 5px;
  transition: 0.4s;
}

.rs-input{
  width: 100%;
  height: 38px;
  padding: 10px 15px;
  background-color: #efefef;
  border-radius: 8px;
  transition: 0.4s; 
}

.rs-select {
  border: none;
}

.rs-feedback {
  padding: 10px 0;
}

.rs-feedback-error {
  color: red;
}

.rs-button {
  min-width: 15rem;
  max-width: 20rem;
  padding: 10px 15px;
  color: white;
  border-radius: 8px;
  transition: 0.4s; 
  height: 42px;
  margin: 1rem 0;
}

.rs-button-summit {
  background-color: #0f52ba;
}

.rs-flex-50 {
  flex-grow: 1;
  flex-basis: 45%;
  margin-right: 0.5rem;
}

.rs-form-2 .rs-form-container > :not(.rs-flex-50) {
  flex-basis: 100%;
  margin-right: 0.5rem;
}

@media screen and (max-width: 750px) {
  .rs-flex-50 {
    flex-grow: 1;
    flex-basis: 100%;
    margin-right: 0.5rem;
  }
}