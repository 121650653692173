:root {
    --ff-primary: "Rubik", sans-serif;

    --ff-body: var(--ff-primary);
    --ff-heading: var(--ff-primary);

    --fw-regular: 400;
    --fw-semi-bold: 500;
    --fw-bold: 700;

    --fs-300: 0.8125rem;
    --fs-400: 0.875rem;
    --fs-500: 0.9375rem;
    --fs-600: 1rem;
    --fs-700: 1.875rem;
    --fs-800: 2.5rem;
    --fs-900: 3.5rem;

    --fs-body: var(--fs-600);
    --fs-primary-heading: var(--fs-800);
    --fs-secondary-heading: var(--fs-700);
    --fs-nav: var(--fs-500);
    --fs-button: var(--fs-300);

    --pd-sm: 0.5rem;
    --pd-md: 0.5rem;
    
    --mr-sm: 0.5rem;
    --mr-md: 0.5rem;
}
ol, ul {
    padding: 0;
}
/* .navbar-name{
    font-size: 25px;
    color: #2955A2;
    min-width: 45rem;
    position: relative;
    padding: 0.5rem
} */
.navbar{
    background-color: #21618c;
    width: 100%;
    z-index: 2;
    position: fixed;
    /* margin-bottom: 4rem; */

}
.sidebar{
    flex: 1;
    width: 90px;
    margin-top: 5rem;
    min-height: 100vh;
    background-color: #fff;
    top: 0;
    bottom: 0;
    z-index: 1;
    transition: 0.5s;
    position: fixed;
    background-color: rgb(233, 238, 243);
}
.sidebar.active{
    left: 0;
}
.sd-header{
    align-items: left;
    justify-content: space-between;
    padding: 15px;
    color: #2955A2;
}
/* .sd-header-name{
    font-size: 21px;
    color: #0f52ba;
} */
.sidebar-overlay{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.4);
    transition: 0.5s;
    opacity: 0;
    visibility: hidden;
}
.sidebar-overlay.active{
    opacity: 1;
    visibility: visible;
}
.sd-body{
    padding: 0px 7px 0px 0px;
    max-height: calc(100vh - 67px);
    overflow-x: hidden;
    margin: 1em 3px 3px 3px;
}
.sd-body ul{
    
    width: 100%;
    margin-bottom: 0;
    padding: 0;
    font-size: 13px;
}
.sd-body ul li{
    list-style: none;
    margin-bottom: 8px;
    text-align: center;
}
.sd-body ul li {
    margin-bottom: 0px;
    font-size: 11px;
}
/* .sd-body ul li {
    display: inline-block;
    width: 100%;
    padding: 10px 16px;
    color: #475f7b;
    background-color: #e5e8ec;
    border-radius: 4px;
    cursor: pointer;
    text-decoration: none;
} */
/* li:hover{
 transform: scale(1,1.1);
 box-shadow: 0px 5px 10px rgba(90, 116, 148, 0.3);
 background-color: rgba(95, 92, 92, 0.4);
} */

.sd-link{
    display: inline-block;
    width: 100%;
    padding: 7px 13px;
    color: #475f7b;
    /* border-radius: 4px; */
    cursor: pointer;
    text-decoration: none;
    margin-bottom: 0px;
}

.sd-link:hover{
    transform: scale(1,1);
    box-shadow: 0px 5px 10px rgba(90, 116, 148, 0.3);
    background-color: #2955A2(95, 92, 92, 0.4);
}
.sd-link-selected{
    display: inline-block;
    color: #FFF;
    width: 100%;
    padding: 10px 16px;
    border-radius: 4px;
    cursor: pointer;
    text-decoration: none;
    margin-bottom: 0px;
    background-color: #21618c;
}

.icon{
    margin-right: 10px;
    height: 18px;
}


/* .select-group{
    
} */

.menu-icon{
    width: 29px;
    height: 25px;
    top: -40px;
    position: relative;
    float: right;
}

.menu-icon:hover{
    cursor: pointer;
    color:grey;
}

.nav-menu-icon{
    height: 4rem;
    position: relative;
    color: #2955A2;
    top: 8px;
    margin: 0px 15px;
}
/* .nav-menu-icon:hover{
    cursor: pointer;
    color: grey;
} */

/* utility classes */

.flex-column {
    display: flex;
    flex-direction: column;
}

.grow-1 {
    flex: 1 1 0;
}

.main-container {
    margin: 0.5rem;
    padding: 0.5rem 1rem;
    border-radius: 1rem;
    border: 1px solid #e6e6e6;
    border-radius: 0.5rem;
    background: #fff;
}

.fw-bold {
    font-weight: var(--fw-bold);
}
.fw-semi-bold {
    font-weight: var(--fw-semi-bold);
}
.fw-regular {
    font-weight: var(--fw-regular);
}

.tab-ul {
    list-style-type: none;
    align-items: center;
    border-radius: 0.3rem;
    background-color: rgb(237, 241, 249);
    max-width: 15rem;
}

.tab-ul-admin {
    list-style-type: none;
    align-items: center;
    border-radius: 0.3rem;
    background-color: rgb(237, 241, 249);
    padding: 0;
    margin-left: auto;
    margin-right: auto;
}

.tab-li {
    flex-grow: 1;
    width: 8rem;
}

.tab-li a {
    cursor: pointer;
    text-align: center;
    display: block;
    margin: 0.15rem;
    padding: 0.35rem 0.5rem;
    font-size: 0.8rem;
    border-radius: 0.15rem;
}

.tab-li-active a {
    background: white;
    box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px 0px;
}

/* general styling */

body {
    font-size: var(--fs-body);
    font-family: var(--ff-body);
}

ul {
    display: block;
    list-style-type: disc;
    margin-block-end: 1rem;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}
  
.custom-card-container p {
    max-width: 75%;
}

.horizontal-separator {
    font-size: 1.5rem;
}

.navbar-icon {
    vertical-align: text-bottom;
}

.button-default {
    border-radius: 5px;
    min-width: 75px;
    cursor: pointer;
    display: inline-block;
}

.wrapper-container{
    opacity: 0.9;
}

.container-right{
    margin-left: 5.7rem !important;
    width: 93%;
    height: 100vh;    
    padding: 0 0 0 0;
    margin-top: 4.5rem !important;
}

.main-body-container {
    margin-left: 5.7rem !important;
    padding: 0 0 0 0;
    margin-top: 4.5rem !important;
}

.main-body {
    width: 100%;
}

.flex-wrap {
    flex-flow: column wrap;
}

.flex-center {
    justify-content: center;
}

.flex-item-center {
    align-items: center;
}

.flex-self-center {
    align-self: center;
}

.flex-baseline {
    align-items: baseline;
}

.flex-stretch {
    flex: 1;
}

.flex-space-between {
    justify-content: space-between;
}

.flex-space-around {
    justify-content: space-around;
}


.flex-end {
    justify-content: end;
}

.rs-widget {
    flex: 1;
    min-width: 23rem;
    min-height: 15rem;
    margin: 0.5rem;
    padding: 0.75rem;
    border: 1px solid #e6e6e6;
    border-radius: 0.5rem;
    background: #fff;
    box-shadow: 0px 1px 4px 0px grey;
}

.rs-widget-basic {
    flex: 1;
    margin: 0.5rem;
    padding: 0.75rem 0.75rem 0 0.75rem;
    border: 1px solid #e6e6e6;
    border-radius: 0.5rem;
}


.rs-w-expand-icon {
    transform: scale(1);
}

.rs-w-expand-icon:hover{
    transform: scale(1.3);
    cursor: pointer;
}

.rs-w-link-external {
    cursor: pointer;
    color: #1ab394;
}

/* dialog */
.rs-dialog {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
}

.rs-d-container {
    max-width: 90%;
    max-height: 90%;    
    box-shadow: rgba(0, 0, 0, 0.25) 
        0px 12px 24px 0px;
    border-radius: 0.5rem;
    padding: 1rem;
    background-color: white;
    overflow: auto;
}

.rs-bar-chart {
    min-height: 15rem;
    min-width: 25rem;
}

.rs-widget-info {
    min-height: 15rem;
    min-width: 20rem;
    max-width: 25rem;
    font-weight: 400;
    border: 0px;
}

.rs-w-b-container {
    min-height: 18rem;
    min-width: 30rem;
}

.rs-widget-body {
    justify-content: space-around
}

.toolbar-item {
    margin: 0 0.5rem 0.5rem 0;
    min-width: 8rem;
    max-width: 20rem;
  }

@media screen and (max-width: 750px) {
    .sidebar {
        display: none;
        position: relative;
    }
    .container-right {
        margin-left: 0 !important;
    }
    .main-body-container {
        margin-left: 0 !important;
    }
    .wrapper-container{
        opacity: 1;
    }
    .rs-bar-chart {
        min-height: 15rem;
        min-width: 15rem;
    }
    .rs-w-b-container {
        min-height:15rem;
        min-width: 100%;
    }
}