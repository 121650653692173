.kpi-item {
    flex: 1;
    min-width: 23rem;
    min-height: 15rem;
    max-width: 30rem;
    margin: 0.5rem;
    border-radius: 1rem;
    border: 1px solid #e6e6e6;
    border-radius: 0.5rem;
    background: #fff;
    box-shadow: 0px 1px 4px 0px grey;
}

.kpi-header-container {
    grid-area: header;
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 0 0 0.5rem;
    color: rgb(71, 95, 123);
    padding: 0.5rem;
    margin-bottom: 0.1rem;
}

.kpi-header {
    color: #262626;
    margin: 0;
    padding: 0.5rem 1rem 0;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.kpi-body-container {
    display: flex;
    flex-direction: column;
}

.kpi-body-1 {
    min-height: 5rem;
}

.kpi-body-1-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: -4rem;
}

.kpi-body-1-1 {
    min-width: 64%;
    min-height: 5rem;
    margin: 0.5rem;
}

.kpi-body-1-2 {
    text-align: center;
    min-width: 32%;
    min-height: 5rem;
}

.kpi-body-2 {
    margin: 0 0.5rem 0 0.5rem ;
}


.kpi-value {
    font-size: 1.5em;
}

.Kpi-modal-chart{
    width: 49%;
    height: 20rem;
    margin: auto;    
    display: flex;
}

.section-header-title {
    font-size: 2.25rem;
}

.expand-icon{
    transform: scale(1);
    position: absolute;
    right: 0;
    bottom: 0;
   }

.expand-icon-2{
    transform: scale(1);
    margin-left: 0.75rem;
}

.expand-icon-2:hover{
    transform: scale(1.3);
    cursor: pointer;
}
