
.widget-header {
    color: #262626;
}

.custom-widget-container {
    min-width: 31.5%;
    min-height: 16rem;
    margin: 0.5rem;
    padding: 0.5rem 1rem;
    border-radius: 1rem;
    border: 1px solid #e6e6e6;
    border-radius: 0.5rem;
    background: #fff;
}

.custom-widget-container-1{
    min-width: 31.5%;
    min-height: 16rem;
    margin: 0.5rem;
    padding: 0.5rem 1rem;
    border-radius: 1rem;
    border: 1px solid #e6e6e6;
    border-radius: 0.5rem;
    background: #fff;
}
.widget-body-container {
    min-height: 90%;
    justify-content: space-between;
}

.custom-widget {
    flex-grow: 1;
}

.custom-widget-body-1 {
    text-align: center;
    padding: 25% 0.5rem 0.5rem 0.5rem;
}

.custom-widget-body-12 {
    text-align: center;
    padding: 7% 0.5rem 0.5rem 0.5rem;
}

.custom-widget-body-1-container {
    justify-content: center;
    align-items: center;
}

.custom-widget-icon {
    height: 4rem;
    width: 4rem;
}

.widget-value-sm {
    font-size: 1.2em;
}

.legend {
    padding-bottom: 0.5rem;
}

.legend-label {
    padding: 0 1.5rem 0 0.2rem;
}

.custom-card-container {
    min-width: 14rem;
    min-height: 11rem;
}