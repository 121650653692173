.css-loading-indicator {
    color: hsl(0, 0%, 80%);
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 8px;
    -webkit-transition: color 150ms;
    transition: color 150ms;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
    font-size: 40px;
    line-height: 1;
    margin-right: 4px;
    text-align: center;
    vertical-align: middle;
    box-sizing: border-box;
  }

@keyframes css-1 {
    from { background-color: white; }
    to { background-color: #0f52ba; }
}

.css-1-Loading-dot {
    animation: css-1 1s ease-in-out 320ms infinite;
    background-color: currentColor;
    border-radius: 1em;
    display: inline-block;
    margin-left: 1em;
    height: 1em;
    vertical-align: top;
    width: 1em;
}

@keyframes css-2 {
    from { background-color: white; }
    to { background-color: #0f52ba; }
}

.css-2-Loading-dot {
    animation: ccs-2 1s ease-in-out 160ms infinite;
    background-color: currentColor;
    border-radius: 1em;
    display: inline-block;
    margin-left: 1em;
    height: 1em;
    vertical-align: top;
    width: 1em;
}

@keyframes css-3 {
    from { background-color: white; }
    to { background-color: #0f52ba; }
}

.css-3-Loading-dot {
    animation: css-3 1s ease-in-out 0ms infinite;
    background-color: currentColor;
    border-radius: 1em;
    display: inline-block;
    height: 1em;
    vertical-align: top;
    width: 1em;
}

.css-at12u2-loadingIndicator {
    color: hsl(0, 0%, 80%);
    font-size: 4px;
    line-height: 1;
    text-align: center;
  }