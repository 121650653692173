* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
.back-logo{
    top: 4rem;
    width: 45rem;
    height: 45rem;
    color: rgba(0,0,0, 0.4);
}

.logo {
width: 80px;
height: 80px;
}

input, button{
    appearance: none;
    border: none;
    outline: none;
}

button {
    background: none;
}

.eye-button{
    position: absolute;
    left: 55px;
}

.basic-select{
    display: block;
    text-align: left;
    width: 100%;
    height: 38px;
    background-color: #efefef;
    border-radius: 8px;
    transition: 0.4s; 
}