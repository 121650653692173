.all-status {
    color: white;
    border-radius: 0.5rem;
}

.beloweop {
    background-color: #fc8d59;
    opacity: 80%;
    color: white;
    border-radius: 0.3rem;
}

.normal {
    background-color: #99d594;
    opacity: 80%;
    color: white;
    border-radius: 0.3rem;
}

.belowmin {
    background-color: #fee08b;
    opacity: 80%;
    color: white;
    border-radius: 0.3rem;
}

.stockedout, .outdated {
    background-color: #d53e4f;
    opacity: 80%;
    color: white;
    border-radius: 0.3rem;
}

.excess, .overstock, .recent {
    background-color: #3288bd;
    opacity: 80%;
    color: white;
    border-radius: 0.3rem;
}